import React, { useState } from 'react';
import '../styles/landingPage.css';
import Button from './button';
import SignUpPopUp from './signUpPopUp';

const LandingPage = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const handleOpenPopUp = () => {
    setIsPopUpOpen(true);
  };

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
  };


    return (
      <main className="landing-page">
        <Button name="Sign Up for Beta →" className="sign-up-button" onClick={handleOpenPopUp}></Button>
        <SignUpPopUp isOpen={isPopUpOpen} setIsPopupOpen={setIsPopUpOpen} onClose={handleClosePopUp} />
      </main>
    );
  };
  
  export default LandingPage;
  