import React, { useState, useEffect, useRef } from 'react';
import Home from "./components/home";
import Header from "./components/header";
import './styles/app.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const canvasRef = useRef(null);
  
    return (
      <div className='app'>
        <Header />
        <Home />
      </div>
    );
}

export default App;
