import React, { useState } from 'react';
import '../styles/signUpPopUp.css';
import Button from './button'

const SignUpPopUp = ({ isOpen, setIsPopupOpen, onClose }) => {
  console.log("isOpen", isOpen);
  console.log("onClose", onClose);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
  });

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field in state
  };

    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault(); // Prevent default form submission behavior
      console.log("Fform Data Submitted:", formData); // Read the input data
      togglePopup(); // Close the popup after submission
    };


    const togglePopup = () => {
      setIsPopupOpen(!isOpen);
    };

  return (
    isOpen && ( 
    <>
    <div className="overlay" onClick={togglePopup}></div>
     <div className="popup" onClick={(e) => e.stopPropagation()}>
      <h3>Get Early Access for Beta</h3>
        {/* First Name */}
        <form onSubmit={handleSubmit}> 
        <div className="form-group">
          <label htmlFor="firstName">First Name<span className="required">*</span></label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Enter your first name"
            value={formData.firstName}
            onChange={handleInputTextChange}
            required
          />
        </div>

        {/* Last Name */}
        <div className="form-group">
          <label htmlFor="lastName">Last Name<span className="required">*</span></label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Enter your last name"
            value={formData.lastName}
            onChange={handleInputTextChange}
            required
          />
        </div>

        {/* Email */}
        <div className="form-group">
          <label htmlFor="email">Email<span className="required">*</span></label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleInputTextChange}
            required
          />
        </div>

        {/* Company */}
        <div className="form-group">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            id="company"
            name="company"
            placeholder="Enter your company (optional)"
            value={formData.company}
            onChange={handleInputTextChange}
          />
        </div>
        <button type="submit" className="sign-up-button">Submit</button>
        </form>
      </div>
      </>
    )
  );
};

export default SignUpPopUp;
