import React from 'react';
import '../styles/header.css';
import '../styles/landingPage.css';
import Button from './button';
import MainLogo from './mainLogo';

const Header = () => {
  const handleAboutClick = () => {
    console.log('About clicked');
  };

  const handleSignUpClick = () => {
    console.log('Sign Up clicked');
  };

  return (
    <header className="header">
      <div className="logo-container">
        <MainLogo/>
      </div>
      <nav className="nav">
        <Button name="About" className="nav-button" onClick={handleAboutClick}></Button>
        <Button name="Sign Up for Beta →" className="nav-button" onClick={handleSignUpClick}></Button>
      </nav>
    </header>
  );
};

export default Header;
